import React from 'react';

const Signin1 = React.lazy(() => import('./Screens/Authentication/SignIn'));
const forgotPassword = React.lazy(() => import('./Screens/Authentication/ResetPassword/forgotPassword'));
const resetPassword = React.lazy(() => import('./Screens/Authentication/ResetPassword/resetPassword'));
const Signout = React.lazy(() => import("./Screens/Authentication/SignIn/Signout"));


const route = [
    { path: '/', exact: true, name: 'Signin 1', component: Signin1 },
    { path: '/logout', exact: true, name: 'Signout', component: Signout },
    { path: '/forgot-password', exact: true, name: 'Reset Password 1', component: forgotPassword },
    { path: '/reset-password', exact: true, name: 'Reset Password Password', component: resetPassword }
];

export default route;